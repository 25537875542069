/* eslint-disable react/jsx-one-expression-per-line */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import useStore from 'hooks/useStore';
import axios from 'axios';

export const globalContext = createContext();
const { Provider } = globalContext;

export default function GlobalProvider({ children }) {
  const store = useStore({
    step: 1
  });

  const [srcMap, setSrcMap] = useState(null);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      axios.get('/src-map.json').then(res => {
        window.srcMap = res.data;
        setSrcMap(true);
      });
    }
  }, []);

  useEffect(() => {
    console.log(store.state, 'state');
  }, [store.state]);
  const dispatch = useCallback(arg => store.dispatch(arg), []);
  return (
    <Provider value={{ state: store.state, dispatch, emit: store.emit, srcMap }}>
      {children}
    </Provider>
  );
}
