/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';
import useGlobal from 'hooks/useGlobal';
import Img from 'gatsby-image';

const isServer = typeof window === 'undefined';
const pixelRatio = isServer ? 2 : window.devicePixelRatio;

function calculateFluid(fluid, props) {
  const srcPaths = fluid.s
    .replace(/,/gm, ' ')
    .split(' ')
    .filter(res => res.length > 10)
    .map(res => res.trim());
  const src = fluid.s.split(' ');
  const srcWebp = fluid.w.split(' ');

  const sizeMap = fluid.w.split(',').map(src => {
    const stringNum = src.split(' ').slice(-1)[0];
    const num = props.ratio
      ? Math.round(parseInt(stringNum) * props.ratio)
      : parseInt(stringNum);
    const ratio = num / pixelRatio;
    const res = {
      size: `${parseInt(stringNum)}px`,
      width: `${Math.round(ratio)}px`
    };
    return res;
  });

  const sizes = sizeMap.reduce((acc, curr, idx) => {
    if (idx === sizeMap.length - 1) {
      return acc.includes(',') ? `${acc} ${curr.width}` : curr.width;
    }
    return `${acc} (max-width: ${curr.size}) ${curr.width},`.trim();
  }, '');
  return {
    base64: props.noBlur ? srcPaths[0] : fluid.b,
    aspectRatio: fluid.r,
    src: src[0],
    srcSet: fluid.s,
    srcWebp: srcWebp[0],
    srcSetWebp: fluid.w,
    originalImg: src[0],
    sizes
  };
}

export default function Image(initialProps) {
  // re-render
  useGlobal();
  const initialPreloadImages =
    initialProps.preloadImages &&
    initialProps.preloadImages.map(img => ({
      src: img
    }));
  const { preloadOnly } = initialProps;
  try {
    const getParams = props => {
      const { src: initialSrc, name, resize, log, ...other } = props;
      const isObject = typeof initialSrc === 'object';
      const srcTitle = isObject ? initialSrc.title : initialSrc;
      const src = resize ? `${srcTitle}-resize_small` : srcTitle;
      const srcName = isObject ? initialSrc.name : src;
      const url = isObject && initialSrc.url;
      const imageSplit = typeof src === 'string' ? src.split(' ') : [];
      const joinImages =
        imageSplit.length > 1 &&
        imageSplit
          .join('-')
          .toLowerCase()
          .replace('.', '')
          .replace(',', '')
          .replace('(', '')
          .replace(')', '');
      const toLowerCaseImage = joinImages || String(src).toLowerCase();
      const myWindow = !isServer && (window.srcMap || window);
      const initialClientFluid =
        myWindow &&
        (myWindow[toLowerCaseImage] || myWindow[src] || myWindow[srcName]);
      const initialSSRFluid =
        isServer &&
        (global.images[toLowerCaseImage] ||
          global.images[src] ||
          global.images[srcName]);
      const fluid = initialSSRFluid || initialClientFluid;
      const context = fluid && JSON.stringify(fluid);
      return { fluid, context, src, url, ...other };
    };
    const { fluid, context, src, url, initialSrc, ...other } = getParams(
      initialProps
    );
    if ((!fluid && url) || (url && process.env.GATSBY_DEVELOPMENT)) {
      if (preloadOnly) return null;
      const fluidProps = {
        base64: url,
        aspectRatio: initialSrc.width / initialSrc.height,
        src: url,
        srcSet: url,
        sizes: '(max-width: 800px) 100vw, 3000px',
        originalImg: url,
        originalName: url
      };
      return <Img fluid={fluidProps} {...initialProps} />;
    }
    if (!fluid) {
      if (preloadOnly) return null;
      return <span style={{ position: 'absolute' }} data-no-fluid={src} />;
    }
    const preloadImages =
      initialPreloadImages &&
      initialPreloadImages.map((img, idx) => (
        <Image {...img} preloadOnly key={`preload-${idx}`} />
      ));
    return (
      <>
        <Helmet>
          <script type="text/javascript">
            {`window['${src}'] = ${context};`}
          </script>
        </Helmet>
        {preloadImages}
        {!preloadOnly && (
          <Img fluid={calculateFluid(fluid, initialProps)} {...initialProps} />
        )}
      </>
    );
  } catch (e) {
    if (preloadOnly) return null;
    const { src: initialSrc, resize } = initialProps;
    const isObject = typeof initialSrc === 'object';
    const srcTitle = isObject ? initialSrc.title : initialSrc;
    const src = resize ? `${srcTitle}-resize_small` : srcTitle;
    return <span style={{ position: 'absolute' }} data-error={src} />;
  }
}
