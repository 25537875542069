import React from 'react';

import s from './container.module.scss';

export default React.forwardRef(({
  children,
  className,
  primary,
  secondary,
  tertiary,
  grey,
  el,
  ...props
}, ref) => {
  const primaryClasses = primary ? s.primary : '';
  const secondaryClasses = secondary ? s.secondary : '';
  const tertiaryClasses = tertiary ? s.tertiary : '';
  const greyClasses = grey ? s.grey : '';
  const colorClasses = primaryClasses || secondaryClasses || tertiaryClasses || greyClasses;
  const classes = `${s.container} ${className ||
    ''} ${colorClasses}`;
    const Tag = el || 'section';
  return (
    <Tag className={classes} {...props} ref={ref}>
      <div className="container">{children}</div>
    </Tag>
  );
});
