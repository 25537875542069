import smoothscroll from 'smoothscroll-polyfill';
import {
  wrapRootElement as rootElement,
  wrapPageElement as pageElement
} from './root';

smoothscroll.polyfill();
// export const disableCorePrefetching = () => true;
export const wrapRootElement = rootElement;
export const wrapPageElement = pageElement;
