import { useReducer, useMemo } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'callback':
      return action.cb(state);
    case 'update':
      return action.cb
        ? {
            ...state,
            ...action.cb(state)
          }
        : {
            ...state,
            ...action.payload
          };
    case 'reset':
      return action.payload;
    default:
      return state;
  }
}

export default function useStore(initialState = {}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const emit = useMemo(
    () => ({
      cb: cb =>
        dispatch({
          type: 'callback',
          cb: prevState => cb(prevState)
        }),
      update: newState =>
        dispatch({
          type: 'update',
          payload: newState
        }),
      updateCb: cb =>
        dispatch({
          type: 'update',
          cb: prevState => cb(prevState)
        }),
      reset: newState =>
        dispatch({
          type: 'reset',
          payload: newState
        })
    }),
    []
  );
  return { state: state || {}, dispatch, emit };
}
