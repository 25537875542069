import React from 'react';
import s from './headline.module.scss';

function Headline(props) {
  const {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    center,
    underline,
    className,
    wrapClassName,
    primary,
    secondary,
    tertiary,
    children,
    white,
    black,
    html,
  } = props;
  const primaryClass = primary ? s.primary : '';
  const secondaryClass = secondary ? s.secondary : '';
  const tertiaryClass = tertiary ? s.tertiary : '';
  const whiteClass = white ? s.white : '';
  const blackClass = black ? s.black : '';
  const centerClass = center ? s.center : '';
  const colorClass = `${primaryClass ||
    secondaryClass ||
    tertiaryClass ||
    whiteClass ||
    blackClass} ${centerClass} ${className || ''}`.trim();

  if (h1) {
    return (
      <div className={`${s.root} ${wrapClassName || ''}`}>
        <h1
          dangerouslySetInnerHTML={{ __html: html }}
          className={`${colorClass} ${s.h1}`}
        >
          {children}
        </h1>
        {underline && (
          <div className={s.flex}>
            <div className={s.underlineBig} />
            <div className={s.underline} />
          </div>
        )}
      </div>
    );
  }

  if (h2) {
    return (
      <div className={`${s.root} ${wrapClassName || ''}`}>
        <h2
          dangerouslySetInnerHTML={{ __html: html }}
          className={`${colorClass} ${s.h2}`}
        >
          {children}
        </h2>
        {underline && (
          <div className={s.flex}>
            <div className={s.underlineBig} />
            <div className={s.underline} />
          </div>
        )}
      </div>
    );
  }

  if (h3) {
    return (
      <div className={`${s.root} ${wrapClassName || ''}`}>
        <h3
          dangerouslySetInnerHTML={{ __html: html }}
          className={`${colorClass} ${s.h3}`}
        >
          {children}
        </h3>
        {underline && (
          <div className={s.flex}>
            <div className={s.underlineBig} />
            <div className={s.underline} />
          </div>
        )}
      </div>
    );
  }

  if (h4) {
    return (
      <div className={`${s.root} ${wrapClassName || ''}`}>
        <h4
          dangerouslySetInnerHTML={{ __html: html }}
          className={`${colorClass} ${s.h4}`}
        >
          {children}
        </h4>
        {underline && (
          <div className={s.flex}>
            <div className={s.underlineBig} />
            <div className={s.underline} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`${s.root} ${wrapClassName || ''}`}>
      <h1
        dangerouslySetInnerHTML={{ __html: html }}
        className={`${colorClass} ${s.h1}`}
      >
        {children}
      </h1>
      {underline && (
        <div className={s.flex}>
          <div className={s.underlineBig} />
          <div className={s.underline} />
        </div>
      )}
    </div>
  );
}

export default Headline;
