import React from 'react';
import Layout from 'layout';
import GlobalProvider from 'context/global';
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
// styles
import './styles/main.scss';

export const wrapRootElement = ({ element }) => {
  return(
    <DndProvider backend={Backend}>
    <GlobalProvider>
      {element}
    </GlobalProvider>
   </DndProvider>
  )
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
