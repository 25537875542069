import React from 'react';
import Header from './header';
import Footer from './footer';

const sites = {

  redirectBaruta: {
    logo: 'logoBaruta',
    title: 'Programa de Asistencia Médica PAM del municipio Baruta',
    pdfPath: '/b-terminos-y-condiciones-pam-baruta.pdf',
    cel1: '0212-9095270',
    cel2: '0414-1158604',
    edadMinima: '0',
    version: 'redirectBaruta',
  },

  baruta: {
    logo: 'logoBaruta',
    title: 'Programa de Asistencia Médica PAM del municipio Baruta',
    pdfPath: '/b-terminos-y-condiciones-pam-baruta.pdf',
    cel1: '0212-9095270',
    cel2: '0414-1158604',
    edadMinima: '0',
    version: 'baruta',
  },
  elhatillo: {
    logo: 'logohatillo',
    title: 'Programa de Asistencia Médica PAM del municipio El Hatillo',
    pdfPath: '/terminos-y-condiciones-del-pam-el-hatillo.pdf',
    cel1: '0414-1146339',
    cel2: '0212-9095272',
    edadMinima: '60',
    version: 'elhatillo',
  },
};

const siteData = sites[process.env.GATSBY_SITE];

export default function Layout({ children, ...props }) {
  return (
    <>
      <Header path={props.path} {...siteData} />
      {children}
      <Footer path={props.path} {...siteData} />
    </>
  );
}
