import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import s from './header.module.scss';

export default function Header({
  logo,
  title,
  cel1,
  cel2,
  path,
  edadMinima,
  version,
}) {
  return (
    <Container className={`${s.header}`}>
      <div className={s.wrapper}>
        <div className={s.wrapperHeadline}>
          {version === 'baruta' || version === 'redirectBaruta' ? (
            <div className={s.logos}>
              <Img className={s.img2} src={logo} />
              <div className={s.rayab} />
              <Img className={s.img3} src="pamlogo1" />

            </div>
          ) : (
            <div className={s.logos}>
              <a
                href="https://gruponueveonce.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img className={s.img1} src="logoPrincipal" />
              </a>
              <div className={s.raya} />
              <Img className={s.img2h} src={logo} />
              <div className={s.raya} />
              <Img className={s.img3h} src="PAM-04" />
            </div>
          )}

          <Headline h3 className={s.headline} html={title} />
          {version === 'elhatillo' && (
            <div
              className={s.paragraph}
              dangerouslySetInnerHTML={{
                __html: `<p>Garantizamos atención gratuita durante la cuarentena del COVID-19 a la población vulnerable mayor de ${edadMinima} años.</p><b>Beneficios:</b><p>- Servicio de ambulancia 24h para emergencias</p><p>- Telemedicina</p><p>- Entrega de medicamentos para enfermedades agudas</p><b>Únicos números de contacto:</b><p>* Medicamentos para enfermedades agudas escribir al whatsapp ${cel1}</p><p>* Ambulancia de emergencia llamar al ${cel2}</p>`,
              }}
            />
          )}
          {version == 'baruta' && (
            <div
              className={s.paragraph}
              dangerouslySetInnerHTML={{
                __html: `<p>Garantizamos atención gratuita para todos los residentes del Municipio Baruta</p><b>Beneficios:</b><p>- Asistencia Médica Domiciliaria y aplicación de tratamientos unidosis 24/7</p><p>- Servicio de ambulancia para emergencias 24/7</p><p>- Telemedicina 24/7</p><p>- Entrega a domicilio de medicamentos para enfermedades agudas</p><b>Únicos números de contacto:</b><p>* Solicitud de servicios: ${cel1}</p><p>* Solicitud de medicamentos para enfermedades agudas escribir al WhatsApp ${cel2}</p>`,
              }}
            />
          )}
        </div>
      </div>
    </Container>
  );
}

// En caso de que deseen nuevamente ingresar el logo de NueveOnce dejp el codigo por aqui
/*   <div className={s.raya} />
              <a
                href="https://gruponueveonce.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img className={s.img1} src="logoPrincipal" />
              </a>
              */
