import React from 'react';
import Img from 'img';
import Headline from 'headline';
import s from './footer.module.scss';

export default function Footer({ path, pdfPath, version }) {
  const redirectBarutaClass = version == 'redirectBaruta' ? s.redirectVersion : '';
  return (
    <footer className={`${s.footer}`}>
      <div className={`${s.footerDiv} ${redirectBarutaClass}`}>
        <p className={s.paragraphFooter}>
        2020 Grupo Nueve Once. Todos los derechos reservados
          {' '}
          <span>
              |
            {' '}
            <a className={s.terminosCond} target="_blank" rel="noopener noreferrer" href={pdfPath}>Términos y Condiciones</a>
          </span>
        </p>
      </div>
    </footer>
  );
}
